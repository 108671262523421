import React from "react";
import { Document, Page, Text, View, StyleSheet, Font, Image } from "@react-pdf/renderer";

Font.register({
  family: "font-normal",
  src: "/fonts/yekanbakh/YekanBakhFaNum-Regular.ttf",
});
Font.register({
  family: "font-semi-bold",
  src: "/fonts/yekanbakh/YekanBakhFaNum-SemiBold.ttf",
});
Font.register({
  family: "font-bold",
  src: "/fonts/yekanbakh/YekanBakhFaNum-Bold.ttf",
});

const PDFTable = ({ data }) => {
  const styles = StyleSheet.create({
    page: {
      padding: 20,
      fontFamily: "font-normal",
      position: "relative",
    },
    dietHeader: {
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "center",
      height: 41,
      backgroundColor: "#F3509D",
      borderRadius: 6,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",

    },

    pkgBox: {
      width: "100%",
      position: "relative",
      display: "flex",
      flexDirection: "row-reverse",
      alignItems: "center",
      gap: "8px",
      marginTop: "8px"
    },

    pkgName: {
      width: "102px",
      height: 40,
      backgroundColor: "#FEEAF3",
      borderRadius: "6px",
      color: "black",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "10px",
      border: "1px solid #FCD3E6"

    },
    pkgDtl: {
      border: "1px solid #FCD3E6",
      borderRadius: 6,
      height: 40,
      display: "flex",
      justifyContent: "flex-start",

      fontSize: "9px",
      flexDirection: "row-reverse",
      flexWrap: "wrap",
      width: "100%",
      padding: 5
    },
    dscBox: {
      display: "flex",
      flexDirection: "row-reverse",

      marginTop: 8,
      paddingLeft: 16,
      paddingRight: 16,
      fontSize: 9,
      textAlign: "right",
      flexWrap: "wrap"
    }

  });
  try {
    return (
      <Document>
        <Page break wrap={true} size="A4" style={styles.page}>
          <View fixed style={{ display: "flex", flexDirection: "row", justifyContent: "center", height: 150, position: "relative" }}>
            <Image alt="" src="/images/pdfpic.png" style={{ height: "100%" }} />
            <View style={{ width: 250, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: 'center', position: "absolute", top: 0, left: 0, gap: 10 }}>
              <Image alt="" src="/images/pdflogo.png" style={{ width: 70 }} />
              <View style={{ height: 46, width: 170, display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>
                <View style={{ width: "100%", display: "flex", gap: 5, flexDirection: "row", alignContent: "center" }}>
                  <Image alt="" src="/images/pdfweb.png" style={{ width: 12 }} />
                  <Text style={{ fontSize: 8, fontFamily: "font-semi-bold" }}>www.bodyness.ir</Text>
                </View>
                <View style={{ width: "100%", display: "flex", gap: 5, flexDirection: "row", alignContent: "center" }}>
                  <Image alt="" src="/images/pdfinsta.png" style={{ width: 12 }} />
                  <Text style={{ fontSize: 8, fontFamily: "font-semi-bold" }}>Bodyness.app</Text>
                </View>
                <View style={{ width: "100%", display: "flex", gap: 5, flexDirection: "row", alignContent: "center" }}>
                  <Image alt="" src="/images/pdfwhatsapp.png" style={{ width: 12 }} />
                  <Text style={{ fontSize: 8, fontFamily: "font-semi-bold" }}>پشتیبان شما در واتساپ: 09352920712</Text>
                </View>
              </View>
            </View>
            <View style={{ width: 189, height: 85, position: "absolute", right: 0 }}>

              <View style={{ display: "flex", flexDirection: "row-reverse" }}>
                <Text style={{ fontSize: 30 }}>برنامه</Text>
                <Text style={{ fontSize: 30, fontFamily: "font-bold" }}>غذایی</Text>
              </View>
              <View style={{ display: "flex", flexDirection: "row-reverse", gap: 4 }}>
                <Text style={{ fontSize: 8 }}>:نام و نام خانوادگی</Text>
                <Text style={{ fontSize: 8, fontFamily: "font-semi-bold" }}>{data?.userName}</Text>
              </View>
              <View style={{ display: "flex", flexDirection: "row-reverse", gap: 4, marginTop: 5 }}>
                <Text style={{ fontSize: 8 }}>:کد اشتراک</Text>
                <Text style={{ fontSize: 8, fontFamily: "font-semi-bold" }}>U-459zuj</Text>
              </View>
            </View>
          </View>
          <View style={{ position: "relative" }} wrap={false}>

            {data?.breakfasts?.length > 0 && (
              <View>

                <View style={styles.dietHeader}>
                  <Text style={{ fontSize: 14, fontWeight: 900, color: "white", fontFamily: "font-bold" }}>صبحانه</Text>

                </View>
                {data?.breakfasts?.map((item, index) => (
                  <>
                    <View style={styles?.pkgBox}>
                      <View style={styles?.pkgName}>
                        <Text>پیشنهاد {index + 1}</Text>
                        <View style={{ display: "flex", flexDirection: "row-reverse", justifyContent: "center", fontSize: 10, color: "#595959" }}>
                          <Text style={{ fontSize: 9 }}>:جمع کالری</Text>
                          <Text style={{ fontFamily: "font-bold", color: "black" }}>{Math.trunc(item?.foodProgramPackages[0]?.totalCalorie)}</Text>
                        </View>
                      </View>
                      <View style={styles.pkgDtl}>
                        {item?.foodProgramPackages[0]?.foodProgramPackageDetails.map((item, index) => (
                          <Text style={{ fontSize: 9 }}>
                            {item?.mainFoodDescription}:{item?.consumedGram}
                            {item?.consumedMeasureFa}
                            {index > 0 && " + "}
                          </Text>
                        ))}
                      </View>
                    </View>
                    <View style={styles.dscBox}>
                      <Text style={{ color: "#F02484" }}>:توضیحات</Text>
                      <Text style={{ marginRight: 5, color: "black" }}>{item?.foodProgramPackages[0].description}</Text>
                    </View>
                  </>
                ))}
              </View>
            )}
            {data?.lunches?.length > 0 && (
              <View style={{ marginTop: 10 }}>
                <View style={styles.dietHeader}>
                  <Text style={{ fontSize: 14, fontWeight: 900, color: "white", fontFamily: "font-bold" }}>ناهار</Text>
                </View>
                {data?.lunches?.map((item, index) => (
                  <>
                    <View style={styles?.pkgBox}>
                      <View style={styles?.pkgName}>
                        <Text>پیشنهاد {index + 1}</Text>
                        <View style={{ display: "flex", flexDirection: "row-reverse", justifyContent: "center", fontSize: 10, color: "#595959" }}>
                          <Text style={{ fontSize: 9 }}>:جمع کالری</Text>
                          <Text style={{ fontFamily: "font-bold", color: "black" }}>{Math.trunc(item?.foodProgramPackages[0]?.totalCalorie)}</Text>
                        </View>
                      </View>
                      <View style={styles.pkgDtl}>
                        {item?.foodProgramPackages[0]?.foodProgramPackageDetails.map((item, index) => (
                          <Text style={{ fontSize: 9 }}>
                            {item?.mainFoodDescription}:{item?.consumedGram}
                            {item?.consumedMeasureFa}
                            {index > 0 && " + "}
                          </Text>
                        ))}
                      </View>
                    </View>
                    <View style={styles.dscBox}>
                      <Text style={{ color: "#F02484" }}>:توضیحات</Text>
                      <Text style={{ marginRight: 5, color: "black" }}>{item?.foodProgramPackages[0].description}</Text>
                    </View>
                  </>
                ))}
              </View>
            )}
            {/* <Image alt="" src="/images/pdffooter.png" style={{ height: 16, position: "absolute", bottom: "-50px" }} />
            */}
          </View>
          <View style={{ position: "relative" }} wrap={false}>
            {data?.dinners?.length > 0 && (
              <View>

                <View style={styles.dietHeader}>
                  <Text style={{ fontSize: 14, fontWeight: 900, color: "white", fontFamily: "font-bold" }}>شام</Text>
                </View>
                {data?.dinners?.map((item, index) => (
                  <>
                    <View style={styles?.pkgBox}>
                      <View style={styles?.pkgName}>
                        <Text>پیشنهاد {index + 1}</Text>
                        <View style={{ display: "flex", flexDirection: "row-reverse", justifyContent: "center", fontSize: 10, color: "#595959" }}>
                          <Text style={{ fontSize: 9 }}>:جمع کالری</Text>
                          <Text style={{ fontFamily: "font-bold", color: "black" }}>{Math.trunc(item?.foodProgramPackages[0]?.totalCalorie)}</Text>
                        </View>
                      </View>
                      <View style={styles.pkgDtl}>
                        {item?.foodProgramPackages[0]?.foodProgramPackageDetails.map((item, index) => (
                          <Text style={{ fontSize: 9 }}>
                            {item?.mainFoodDescription}:{item?.consumedGram}
                            {item?.consumedMeasureFa}
                            {index > 0 && " + "}
                          </Text>
                        ))}
                      </View>
                    </View>
                    <View style={styles.dscBox}>
                      <Text style={{ color: "#F02484" }}>:توضیحات</Text>
                      <Text style={{ marginRight: 5, color: "black" }}>{item?.foodProgramPackages[0].description}</Text>
                    </View>
                  </>
                ))}
              </View>
            )}
            {data?.snackBeforeLunches?.length > 0 && (
              <View style={{ marginTop: 10 }}>
                <View style={styles.dietHeader}>
                  <Text style={{ fontSize: 14, fontWeight: 900, color: "white", fontFamily: "font-bold" }}>ناهار</Text>
                </View>
                {data?.snackBeforeLunches?.map((item, index) => (
                  <>
                    <View style={styles?.pkgBox}>
                      <View style={styles?.pkgName}>
                        <Text>پیشنهاد {index + 1}</Text>
                        <View style={{ display: "flex", flexDirection: "row-reverse", justifyContent: "center", fontSize: 10, color: "#595959" }}>
                          <Text style={{ fontSize: 9 }}>:جمع کالری</Text>
                          <Text style={{ fontFamily: "font-bold", color: "black" }}>{Math.trunc(item?.foodProgramPackages[0]?.totalCalorie)}</Text>
                        </View>
                      </View>
                      <View style={styles.pkgDtl}>
                        {item?.foodProgramPackages[0]?.foodProgramPackageDetails.map((item, index) => (
                          <Text style={{ fontSize: 9 }}>
                            {item?.mainFoodDescription}:{item?.consumedGram}
                            {item?.consumedMeasureFa}
                            {index > 0 && " + "}
                          </Text>
                        ))}
                      </View>
                    </View>
                    <View style={styles.dscBox}>
                      <Text style={{ color: "#F02484" }}>:توضیحات</Text>
                      <Text style={{ marginRight: 5, color: "black" }}>{item?.foodProgramPackages[0].description}</Text>
                    </View>
                  </>
                ))}
              </View>
            )}

          </View>


        </Page>
      </Document>
    );
  } catch (error) {
    console.error("خطا در تولید PDF:", error);
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <Text>خطایی رخ داده است. لطفاً داده‌ها را بررسی کنید.</Text>
        </Page>
      </Document>
    );
  }
};

export default PDFTable;



{/*   {data?.lunches?.length > 0 && (
              <View style={styles.tableRow}>
                <View
                  style={{
                    ...styles.tableCol,
                    height: data?.lunches?.length * 90,
                    justifyContent: "center",
                    borderLeft: "1px solid silver",
                  }}
                >
                  <Text style={{ ...styles.tableCell, fontSize: 15 }}>ناهار</Text>
                </View>
                <View style={styles.tableCol2}>
                  {data?.lunches?.map((item, index) => (
                    <>
                      <View style={styles?.pkgName}>
                        <Text style={{ fontSize: 9, color: "white" }}>پکیچ {index + 1}</Text>
                      </View>
                      <View style={styles.pkgBox}>
                        {item?.foodProgramPackages[0]?.foodProgramPackageDetails.map((item, index) => (
                          <Text style={{ fontSize: 9 }}>
                            {item?.mainFoodDescription}:{item?.consumedGram}
                            {item?.consumedMeasureFa}
                            {index > 0 && " + "}
                          </Text>
                        ))}
                      </View>
                      <View style={{ fontSize: 9, textAlign: "right", color: "red" }}>
                        <Text>:توضیحات</Text>
                        <Text>{item?.foodProgramPackages[0].description}</Text>
                      </View>
                    </>
                  ))}
                </View>
              </View>
            )}
            {data?.snackBeforeLunches?.length > 0 && (
              <View style={styles.tableRow}>
                <View
                  style={{
                    ...styles.tableCol,
                    height: data?.snackBeforeLunches?.length * 90,
                    justifyContent: "center",
                    borderLeft: "1px solid silver",
                  }}
                >
                  <Text style={{ ...styles.tableCell, fontSize: 15 }}>میان وعده</Text>
                </View>
                <View style={styles.tableCol2}>
                  {data?.snackBeforeLunches?.map((item, index) => (
                    <>
                      <View style={styles?.pkgName}>
                        <Text style={{ fontSize: 9, color: "white" }}>پکیچ {index + 1}</Text>
                      </View>
                      <View style={styles.pkgBox}>
                        {item?.foodProgramPackages[0]?.foodProgramPackageDetails.map((item, index) => (
                          <Text style={{ fontSize: 9 }}>
                            {item?.mainFoodDescription}:{item?.consumedGram}
                            {item?.consumedMeasureFa}
                            {index > 0 && " + "}
                          </Text>
                        ))}
                      </View>
                      <View style={{ fontSize: 9, textAlign: "right", color: "red" }}>
                        <Text>:توضیحات</Text>
                        <Text>{item?.foodProgramPackages[0].description}</Text>
                      </View>
                    </>
                  ))}
                </View>
              </View>
            )}
            {data?.dinners?.length > 0 && (
              <View style={styles.tableRow}>
                <View
                  style={{
                    ...styles.tableCol,
                    height: data?.dinners?.length * 90,
                    justifyContent: "center",
                    borderLeft: "1px solid silver",
                  }}
                >
                  <Text style={{ ...styles.tableCell, fontSize: 15 }}>شام</Text>
                </View>
                <View style={styles.tableCol2}>
                  {data?.dinners?.map((item, index) => (
                    <>
                      <View style={styles?.pkgName}>
                        <Text style={{ fontSize: 9, color: "white" }}>پکیچ {index + 1}</Text>
                      </View>
                      <View style={styles.pkgBox}>
                        {item?.foodProgramPackages[0]?.foodProgramPackageDetails.map((item, index) => (
                          <Text style={{ fontSize: 9 }}>
                            {item?.mainFoodDescription}:{item?.consumedGram}
                            {item?.consumedMeasureFa}
                            {index > 0 && " + "}
                          </Text>
                        ))}
                      </View>
                      <View style={{ fontSize: 9, textAlign: "right", color: "red" }}>
                        <Text>:توضیحات</Text>
                        <Text>{item?.foodProgramPackages[0].description}</Text>
                      </View>
                    </>
                  ))}
                </View>
              </View>
            )} */}