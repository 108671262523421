import React from "react";

import BodyNessPdf from "../../../../../components/PDF/ExercisePlanCoach";
import PDFDownloader from "../../../../../components/common/PDFDownloader";


/* const PDFDownloader = ({ data, fileName = "document.pdf" }) => {
    const [reloadKey, setReloadKey] = useState(0); 

    return (
        <section>
          
            <button
                onClick={() => setReloadKey(prev => prev + 1)}
                className="mb-2 px-4 py-2 bg-blue-500 text-white rounded"
            >
                بازگذاری مجدد PDF
            </button>

            <BlobProvider key={reloadKey} document={<BodyNessPdf data={data} />}>
                {({ blob, url, loading }) =>
                    loading ? (
                        "در حال ساخت PDF..."
                    ) : (
                        <a
                            href={url}
                            download={fileName}
                            className="flex justify-center items-center gap-2 w-[140px] p-2 font-semibold h-[40px] mr-auto bg-whitesmoke bg-[#fff4f4] text-red-500 rounded-full"
                        >
                            <span> دانلود PDF</span>
                            <img className="h-full" src={svgFileUrl + "download.svg"} alt="Download" />
                        </a>
                    )
                }
            </BlobProvider>
        </section>
    );
};

export default PDFDownloader; */
const ExercisePlanPage = ({ data }) => {


    return (
        <div>
            <h1>برنامه تمرینی</h1>
            <PDFDownloader
                data={data}
                fileName="exercise_plan.pdf"
                PdfDocumentComponent={BodyNessPdf} // کامپوننت PDF خاص این صفحه
            />
        </div>
    );
};

export default ExercisePlanPage;