import { Fragment, useState } from "react";
import { Button, Modal } from "../../../../components/common";
import { baseFileUrl, imagesFileUrl } from "../../../../config";
import Item from "./components/Item";
import { Link } from "react-router-dom";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const packages = {
    package1: [
      { id: 11, name: "یک ماهه", price: "350,000 تومان", desc: "توضیحات این بخش تستی است" },
      { id: 51, name: "سه ماهه", price: "950,000 تومان", desc: "توضیحات این بخش تستی است" },
      { id: 52, name: "شش ماهه", price: "1,900,000 تومان", desc: "توضیحات این بخش تستی است" }
    ],
    package2: [
      { id: 12, name: "یک ماهه", price: "350,000 تومان", desc: "توضیحات این بخش تستی است" },
      { id: 53, name: "سه ماهه", price: "950,000 تومان", desc: "توضیحات این بخش تستی است" },
      { id: 58, name: "شش ماهه", price: "1,900,000 تومان", desc: "توضیحات این بخش تستی است" }
    ],
    package3: [
      { id: 13, name: "یک ماهه", price: "350,000 تومان", desc: "توضیحات این بخش تستی است" },
      { id: 54, name: "سه ماهه", price: "950,000 تومان", desc: "توضیحات این بخش تستی است" },
      { id: 59, name: "شش ماهه", price: "1,900,000 تومان", desc: "توضیحات این بخش تستی است" }
    ],
    package4: [
      { id: 14, name: "یک ماهه", price: "350,000 تومان", desc: "توضیحات این بخش تستی است" },
      { id: 55, name: "سه ماهه", price: "950,000 تومان", desc: "توضیحات این بخش تستی است" },
      { id: 56, name: "شش ماهه", price: "1,900,000 تومان", desc: "توضیحات این بخش تستی است" }
    ],
    package5: [
      { id: 15, name: "یک ماهه", price: "350,000 تومان", desc: "توضیحات این بخش تستی است" },
      { id: 56, name: "سه ماهه", price: "950,000 تومان", desc: "توضیحات این بخش تستی است" },
      { id: 61, name: "شش ماهه", price: "1,900,000 تومان", desc: "توضیحات این بخش تستی است" }
    ],
    package6: [
      { id: 16, name: "یک ماهه", price: "350,000 تومان", desc: "توضیحات این بخش تستی است" },
      { id: 57, name: "سه ماهه", price: "950,000 تومان", desc: "توضیحات این بخش تستی است" },
      { id: 62, name: "شش ماهه", price: "1,900,000 تومان", desc: "توضیحات این بخش تستی است" }
    ],

  }
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [showModal, setShowModal] = useState(false)
  const [currentPackage, setCurrentPackage] = useState([])
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const showPackageDtl = (packageDtl) => {

    setShowModal(true)
    setCurrentPackage(packageDtl)
  }
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-[90%] h-auto mb-[50px] xl:mb-[80px] relative rounded-[16px] overflow-hidden px-4 xl:px-12 py-14  xl:w-[1128px] mx-auto bg-[linear-gradient(264.34deg,_#f1e8fc47_8.02%,_#F1E8FC_71.05%)]">
      <section className="w-full justify-between  relative flex">
        <section className="text-[#191919] mb-11 xl:mb-0 text-[22px] xl:text-[32px] font-bold">
          <h2>رژیم‌های درمانی</h2>
        </section>
      </section>
      <img src={baseFileUrl + "/shapebcircyle.png"} alt="" className="w-full absolute top-0 -right-[100px]   h-auto " />
      <section className="grid grid-cols-1 xl:grid-cols-2 h-auto justify-center z-10 relative  w-full gap-5 xl:gap-8 py-11">
        <Item showPackageDtl={showPackageDtl} packages={packages.package1} display="hidden xl:flex" src="/pic (6).png" price="350,000" title="رژیم ویژه بیماران قلبی و عروق" />
        <Item showPackageDtl={showPackageDtl} packages={packages.package2} display="hidden xl:flex" src="/pic (7).png" price="350,000" title="رژیم درمانی اختلالات کبدی" />
        <Item showPackageDtl={showPackageDtl} packages={packages.package3} display="hidden xl:flex" src="/pic (2).png" price="350,000" title="رژیم درمانی گوارشی" />
        <Item showPackageDtl={showPackageDtl} packages={packages.package4} src="/pic (3).png" price="350,000" title="رژیم درمانی بیماران کلیوی" />
        <Item showPackageDtl={showPackageDtl} packages={packages.package5} src="/pic (5).png" price="350,000" title="رژیم درمانی بیماران صرعی" />
        <Item showPackageDtl={showPackageDtl} packages={packages.package6} src="/pic (4).png" price="350,000" title="رژیم درمان کنترل چربی و کلسترول" />
      </section>
      <Modal width={600} open={showModal} onClose={() => setShowModal(false)}>
        {currentPackage.map((item, index) => (<Fragment key={index}>
          <section className="bg-[#fef9ff] rounded-lg p-4 mt-5">
            <section className="grid grid-cols-1 items-center xl:grid-cols-3 gap-5 mt-4">
              <section className="flex gap-2">
                <img className="w-[24px] h-[24px]" src={imagesFileUrl + "icons/pkg-check.png"} alt="" />
                <span className="">{item.name}</span>
              </section>

              <span className="text-purple">{item.price}</span>
              <Link to={"/shopping-cart/" + item.id}>
                <Button className="h-[30px] rounded-full transition-all hover:shadow-lg">
                  خرید پکیچ
                </Button>
              </Link>
            </section>
            <hr className="border-silver my-5" />
            <section className="flex gap-2">
              <span className="text-[14px] font-light">{item?.desc}</span>
            </section>
          </section>
        </Fragment>))}
      </Modal>
    </section>
  );
}
