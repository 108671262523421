import LooseWeight from "./components/LooseWeight";
import CalorieCounter from "./components/CalorieCounter";
import Child from "./components/Child";
import GainWeight from "./components/GainWeight";
import Pregnant from "./components/Pregnant";
import Sportsman from "./components/Sportsman";
import Maintain from "./components/Maintain";
import Baby from "./components/Baby";
import { Button, Modal } from "../../../../components/common";
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { imagesFileUrl } from "../../../../config";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [showModal, setShowModal] = useState(false)
  const [currentPackage, setCurrentPackage] = useState([])
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const showPackageDtl = (packageDtl) => {
    setShowModal(true)
    setCurrentPackage(packageDtl)
  }
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="w-full xl:mb-[80px] my-[60px] h-auto xl:mt-[150px] ">
      <h3 className="text-center leading-[34.1px] xl:leading-[49.6px] text-[#191919] font-bold text-[22px] xl:text-[32px]">رژیم‌های تناسب اندام</h3>
      <p className="text-center xl:block hidden leading-[24.8px] mt-8 text-[#3E4151] font-bold text-[16px]">با استفاده از ورزش‌های تناسب اندام، به بهترین نتایج در تناسب اندام دست یابید.</p>
      <section className="grid xl:grid-cols-3 mt-8 xl:mt-[64px] w-[90%] xl:w-[1184px] xl:px-8 mx-auto gap-6">
        <LooseWeight showPackageDtl={showPackageDtl} packages={[{ id: 3, name: "یک ماهه", price: "350,000 تومان", desc: "توضیحات این بخش تستی است" }, { id: 41, name: "سه ماهه", price: "950,000 تومان", desc: "توضیحات این بخش تستی است" }, { id: 42, name: "شش ماهه", price: "1,900,000 تومان", desc: "توضیحات این بخش تستی است" }]} />
        <CalorieCounter showPackageDtl={showPackageDtl} packages={[{ id: 4, name: "یک ماهه", price: "350,000 تومان", desc: "توضیحات این بخش تستی است" }, { id: 43, name: "سه ماهه", price: "950,000 تومان", desc: "توضیحات این بخش تستی است" }, { id: 44, name: "شش ماهه", price: "1,900,000 تومان", desc: "توضیحات این بخش تستی است" }]} />
        <GainWeight showPackageDtl={showPackageDtl} packages={[{ id: 6, name: "یک ماهه", price: "350,000 تومان", desc: "توضیحات این بخش تستی است" }, { id: 47, name: "سه ماهه", price: "950,000 تومان", desc: "توضیحات این بخش تستی است" }, { id: 48, name: "شش ماهه", price: "1,900,000 تومان", desc: "توضیحات این بخش تستی است" }]} />
      </section>
      <section className="grid xl:grid-cols-4 mt-6 w-[90%] xl:w-[1184px] xl:px-8 mx-auto gap-6">
        <Baby showPackageDtl={showPackageDtl} packages={[{ id: 10, name: "یک ماهه", price: "770,000 تومان", desc: "توضیحات این بخش تستی است" }, { id: 37, name: "شش ماهه اول", price: "2,900,000 تومان", desc: "توضیحات این بخش تستی است" }, { id: 38, name: "شش ماهه دوم  +  توصیه های غذایی کمکی نوزاد", price: "5,800,000 تومان", desc: "توضیحات این بخش تستی است" }]} />
        <Pregnant showPackageDtl={showPackageDtl} packages={[{ id: 7, name: "سه ماهه اول", price: "1,500,000 تومان", desc: "توضیحات این بخش تستی است" }, { id: 35, name: "سه ماهه دوم", price: "1,500,000 تومان", desc: "توضیحات این بخش تستی است" }, { id: 36, name: "سه ماهه سوم", price: "1,500,000 تومان", desc: "توضیحات این بخش تستی است" }]} />
      </section>
      <section className="grid xl:grid-cols-3 mt-6 w-[90%] xl:w-[1184px] xl:px-8 mx-auto gap-6">
        <Sportsman showPackageDtl={showPackageDtl} packages={[{ id: 8, name: "یک ماهه", price: "500,000 تومان", desc: "توضیحات این بخش تستی است" }, { id: 39, name: "سه ماهه", price: "1,300,000 تومان", desc: "توضیحات این بخش تستی است" }, { id: 40, name: "شش ماهه", price: "2,700,000 تومان", desc: "توضیحات این بخش تستی است" }]} />
        <Maintain showPackageDtl={showPackageDtl} packages={[{ id: 9, name: "یک ماهه", price: "350,000 تومان", desc: "توضیحات این بخش تستی است" }, { id: 49, name: "سه ماهه", price: "950,000 تومان", desc: "توضیحات این بخش تستی است" }, { id: 50, name: "شش ماهه", price: "1,900,000 تومان", desc: "توضیحات این بخش تستی است" }]} />
        <Child showPackageDtl={showPackageDtl} packages={[{ id: 5, name: "یک ماهه", price: "350,000 تومان", desc: "توضیحات این بخش تستی است" }, { id: 45, name: "سه ماهه", price: "950,000 تومان", desc: "توضیحات این بخش تستی است" }, { id: 46, name: "شش ماهه", price: "1,900,000 تومان", desc: "توضیحات این بخش تستی است" }]} />
      </section>
      <Modal width={800} open={showModal} onClose={() => setShowModal(false)}>
        {currentPackage.map((item, index) => (<Fragment key={index}>
          <section className="bg-[#fef9ff] rounded-lg p-4 mt-5">
            <section className="grid grid-cols-1 items-center xl:grid-cols-3 gap-5 mt-4">
              <section className="flex gap-2">
                <img className="w-[24px] h-[24px]" src={imagesFileUrl + "icons/pkg-check.png"} alt="" />
                <span className=" font-light">{item.name}</span>
              </section>

              <span className="text-purple">{item.price}</span>
              <Link to={"/shopping-cart/" + item.id}>
                <Button className="h-[30px] rounded-full transition-all hover:shadow-lg">
                  خرید پکیچ
                </Button>
              </Link>
            </section>
            {/*   <hr className="border-silver my-5" /> */}
            {/*   <section className="flex gap-2">
              <span className="text-[14px] font-light">{item?.desc}</span>
            </section> */}
          </section>
        </Fragment>))}
      </Modal>
    </section>
  );
}
