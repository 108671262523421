import { useState, useEffect } from "react";
import { useAxiosWithToken } from "../../../hooks";
import api from "../../../api";
import MealTypeList from "./components/MealTypeList";
/* import DownloadPdf from "./components/FoodPdfDownload"; */
import { useBasicInfoStore } from "../../../store/dashboard/basicInfo";
import PDFDownloader from "./components/PdfDownloader";
/* import FoodListPdf from "../../../components/PDF/FoodListPdf"; */
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index({ reload, reloadTable }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const basicInfo = useBasicInfoStore((state) => state.basicInfo);
  const firstName = useBasicInfoStore((state) => state.basicInfo.firstName);
  const lastName = useBasicInfoStore((state) => state.basicInfo.lastName);
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [dietTabActive, setDietTabActive] = useState(1);

  // ─── Functions ──────────────────────────────────────────────────────────────────
  const getExerciseList = () => {
    setLoading(true);
    useAxiosWithToken
      .get(api.foodProgramPackage.userPackageList)
      .then((res) => {
        setLoading(false);
        setData(res?.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    getExerciseList();
  }, [reload]);

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="mb-12 bg-white rounded-[8px] px-1 xl:px-[34px] py-[44px] shadow-md">
      <section className="px-[20px] xl:px-0 flex justify-between items-center">
        <span className="font-bold text-lg">برنامه های غذایی</span>
      </section>
      {<section className="my-2">{(data?.breakfasts?.length > 0 || data?.snackBeforeLunches?.length > 0 || data?.dinners?.length > 0 || data?.lunches?.length > 0) && basicInfo.firstName && <PDFDownloader data={{ ...data, userName: firstName + " " + lastName }} />}</section>}
      <section className="w-full min-h-[60px] mt-5 h-auto mb-8 flex flex-wrap justify-between items-center rounded-md xl:rounded-full bg-white m-auto shadow-[0px_4px_20px_-6px_#A193F8B2] p-2">
        <button onClick={() => setDietTabActive(1)} className={`p-2 rounded-full min-w-[100px] ${dietTabActive === 1 && `bg-[#F1E8FC]`}`}>
          صبحانه
        </button>
        <button onClick={() => setDietTabActive(2)} className={`p-2 rounded-full min-w-[100px] ${dietTabActive === 2 && `bg-[#F1E8FC]`}`}>
          میان وعده
        </button>
        <button onClick={() => setDietTabActive(3)} className={`p-2 rounded-full min-w-[100px] ${dietTabActive === 3 && `bg-[#F1E8FC]`}`}>
          ناهار
        </button>
        <button onClick={() => setDietTabActive(4)} className={`p-2 rounded-full min-w-[100px] ${dietTabActive === 4 && `bg-[#F1E8FC]`}`}>
          شام
        </button>
      </section>
      {dietTabActive === 1 && <MealTypeList loading={loading} data={data?.breakfasts} reloadTable={reloadTable} />}
      {dietTabActive === 2 && <MealTypeList loading={loading} data={data?.snackBeforeLunches} reloadTable={reloadTable} />}
      {dietTabActive === 3 && <MealTypeList loading={loading} data={data?.lunches} reloadTable={reloadTable} />}
      {dietTabActive === 4 && <MealTypeList loading={loading} data={data?.dinners} reloadTable={reloadTable} />}
    </section>
  );
}
