import { baseFileUrl, imagesFileUrl } from "../../../config";
import { Link } from "react-router-dom";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//
export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <footer className="w-full flex flex-col justify-center items-center h-auto xl:h-auto bg-gradient-to-l from-[#E4E9FE] to-[#DEBFFA]">
      <section className="h-[1000px] xl:h-[380px] relative flex flex-col xl:flex-row justify-center items-center xl:items-start">
        <section className="h-auto px-[20px] pt-[78px] xl:pt-[60px] pb-[40px] xl:h-[219px] w-full xl:w-[1136px]  z-10">
          <section className="flex xl:flex-row flex-col justify-between">
            <section className="w-full xl:w-[390px] h-auto xl:h-[219px]">
              <section className="flex mb-[10px] justify-between w-full">
                <section className="h-auto w-full   rounded-[12px] px-4 xl:px-6 pt-2 pb-5 bg-[#2b0651]">
                  <section className="h-[100px] flex justify-center">
                    <img className="h-full" src={baseFileUrl + "/images/logo-new.png"} alt="" />
                  </section>
                  <section className="flex justify-between mt-3">
                    <section className="w-[95px] h-[95px] xl:w-[109px] xl:h-[109px] bg-white flex justify-center items-center rounded-md">
                      <a referrerpolicy="origin" target="_blank" href="https://trustseal.enamad.ir/?id=499096&Code=mK5rJySTnF5J6WqoH4hv6S83KHCixUtk">
                        <img className="w-[75px] max-w-full" referrerpolicy="origin" src="https://trustseal.enamad.ir/logo.aspx?id=499096&Code=mK5rJySTnF5J6WqoH4hv6S83KHCixUtk" alt="" style={{ cursor: "pointer" }} code="mK5rJySTnF5J6WqoH4hv6S83KHCixUtk" />
                      </a>
                    </section>
                    <section className="w-[95px] h-[95px] xl:w-[109px] xl:h-[109px] bg-white flex justify-center items-center rounded-md">
                      <img className="w-[75px] max-w-full" referrerpolicy="origin" id="rgvjfukzsizpsizpwlaonbqe" style={{ cursor: "pointer" }} onClick={() => window.open("https://logo.samandehi.ir/Verify.aspx?id=369942&p=xlaogvkapfvlpfvlaodsuiwk", "Popup", "toolbar=no, scrollbars=no, location=no, statusbar=no, menubar=no, resizable=0, width=450, height=630, top=30")} alt="logo-samandehi" src="https://logo.samandehi.ir/logo.aspx?id=369942&p=qftiwlbqbsiybsiyshwlodrf" />
                    </section>
                    <section className="w-[95px] h-[95px] xl:w-[109px] xl:h-[109px] bg-white flex justify-center items-center rounded-md">
                      <img className="w-[75px] max-w-full" src={imagesFileUrl + "majazi.png"} alt="" />
                    </section>
                  </section>
                </section>
              </section>
            </section>
            <section className="xl:w-[125px] w-full mb-[60px] xl:h-[219px]">
              <span className="font-semibold text-[20px]">لینک ها</span>
              <Link to="" className="block text-[16px] font-medium mt-6">
                خانه
              </Link>
              <Link to="" className="block text-[16px] font-medium mt-4">
                تمرینات ورزشی
              </Link>
              <Link to="" className="block text-[16px] font-medium mt-4">
                رژیم غذایی
              </Link>
              <Link to="" className="block text-[16px] font-medium mt-4">
                درباره ما
              </Link>
            </section>
            <section className="w-full pl-[40px] mb-[44px] xl:w-[295px] xl:h-[234px]">
              <span className="font-semibold text-[20px]">ارتباط با ما</span>
              <section className="flex mt-6 text-[16px] font-medium">
                <img src={baseFileUrl + "/images/svg/map.svg"} alt="" />
                <span className="mr-3 text-[14px]">خیابان پاسداران، نیستان نهم پلاک ۳۰۲ طبقه اول</span>
              </section>
              <section className="flex mt-6 text-[16px] font-medium">
                <img src={baseFileUrl + "/images/svg/phone.svg"} alt="" />
                <span className="mr-3" style={{ direction: "ltr" }}>
                  021-21001463
                </span>
              </section>
              <section className="flex mt-6 text-[16px] font-medium">
                <img src={baseFileUrl + "/images/svg/mail.svg"} alt="" />
                <span className="mr-3">info@bodyness.ir</span>
              </section>
              <section className="mt-4 flex justify-between w-[208px]">
                <span className="rounded-full cursor-pointer w-[40px] h-[40px] border-[2px] border-[#] hover:border-[#5408A9] hover:bg-[#F1E8FC] transition-all flex justify-center items-center">
                  <img src={baseFileUrl + "/images/svg/twitter.png"} alt="" />
                </span>
                <span className="rounded-full cursor-pointer w-[40px] h-[40px] border-[2px] border-textGray hover:border-[#5408A9] hover:bg-[#F1E8FC] transition-all flex justify-center items-center">
                  <img src={baseFileUrl + "/images/svg/instagram.svg"} alt="" />
                </span>
                <span className="rounded-full cursor-pointer w-[40px] h-[40px] border-[2px] border-textGray hover:border-[#5408A9] hover:bg-[#F1E8FC] transition-all flex justify-center items-center">
                  <img src={baseFileUrl + "/images/svg/linkdin.svg"} alt="" />
                </span>
                <span className="rounded-full cursor-pointer w-[40px] h-[40px] border-[2px] border-textGray hover:border-[#5408A9] hover:bg-[#F1E8FC] transition-all flex justify-center items-center">
                  <img src={baseFileUrl + "/images/svg/facebook.svg"} alt="" />
                </span>
              </section>
            </section>
          </section>
          <section className="h-[52px] pl-[10px] w-full flex xl:flex-row flex-col xl:items-center xl:justify-between pt-[20px] border-t border-2px border-[#A6A9BD] ">
            <span className="text-textGray  order-last font-normal ">© Copyright 2024, All Rights Reserved</span>
            <section className="xl:w-[235px] xl:h-[26px] flex flex-col xl:flex-row mb-4 justify-between">
              <span className="font-normal text-[14px] text-textGray">حریم خصوصی</span>
              <span className="font-normal text-[14px] text-textGray">شرایط و قوانین</span>
            </section>
          </section>
        </section>
      </section>
      <section className="flex">
        <a href="/images/khalaghcert.jpeg"><img src={imagesFileUrl + "khlagh.png"} className="w-[200px]" /></a>

        <a className="m-auto block mb-5" target="_blank" href="https://fontiran.com/license/F5ISP1AT">
          <div style={{ width: "123px", fontSize: "10px" }}>
            <img className="m-auto" src="https://fontiran.com/storage/uploads/images/2023/1/badge2-minjcHOH8.png" width="100" height="100" alt="فونت یکان‌بخ" />
            <span className="text-center mt-1 block">این اپلیکیشن به صورت قانونی از فونت یکان‌بخ استفاده می کند. </span>
          </div>
        </a>
      </section>
    </footer>
  );
}
