import { VideoPlayer } from "../../../components/common";
import { imagesFileUrl } from "../../../config";

//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index({ url }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="mt-[50px]">
      <h2 className="text-[18px] font-bold">ویدئوی معرفی</h2>
      <section className="w-full min-h-[400px] flex justify-center items-center bg-[#554279] rounded-[10px]">
        {url ? <VideoPlayer url={url} /> : <span className="text-lg text-white">ویدئویی وجود ندارد</span>}

        {/* <img
          className="w-full mt-[32px]"
          src={imagesFileUrl + "doctor-profile-video.png"}
          alt=""
        /> */}
      </section>
    </section>
  );
}
