import React from "react";
import { Document, Page, Text, View, StyleSheet, Font, Image } from "@react-pdf/renderer";

// ثبت فونت
Font.register({
  family: "font-normal",
  src: "/fonts/yekanbakh/YekanBakhFaNum-Regular.ttf",
});
Font.register({
  family: "font-semi-bold",
  src: "/fonts/yekanbakh/YekanBakhFaNum-SemiBold.ttf",
});
Font.register({
  family: "font-bold",
  src: "/fonts/yekanbakh/YekanBakhFaNum-Bold.ttf",
});

const PDFTable = ({ data }) => {
  const styles = StyleSheet.create({
    page: {
      padding: 20,
      fontFamily: "font-normal",
      position: "relative",
    },
    wrapper: {
      width: "100%",

    },
    title: {
      fontSize: 15,
      textAlign: "right",
    },
    box: {


      borderRadius: 10,

    },
    header: {
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "space-between",
      backgroundColor: "#7639BA",
      color: "white",
      height: 38,
      borderRadius: 6
    },
    headerBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 100
    },
    headerTitle: {
      fontSize: 10,
    },
    headerValue: {
      fontSize: 9,
      fontFamily: "font-bold"
    },
    table: {
      display: "table",
      borderRadius: 10,

    },
    tableRow: {
      flexDirection: "row-reverse",
      borderRadius: 6,
      border: "1px solid #DDCEEE",
      marginTop: 8,
      height: 38,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"

    },
    tableRowHeader: {
      flexDirection: "row-reverse",
      backgroundColor: "#F1E8FC",
      borderRadius: 6,
      border: "1px solid #DDCEEE",
      height: 40,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: 10
    },


    tableCol: {
      width: "25%",
      padding: 5,
      textAlign: "center",
    },
    tableCell: {
      fontSize: 10,
      textAlign: "center",
      direction: "rtl",
    },
    tableCellHeader: {
      fontSize: 11,
      textAlign: "center",
      direction: "rtl",
      fontFamily: "font-bold"
    },


    descBox: {
      padding: 10,
      width: "100%",
      display: "flex",
      alignItems: "center",
      fontFamily: "font-bold",
      flexDirection: "row-reverse",

    },

  });

  try {
    return (
      <Document>
        <Page break wrap={true} size="A4" style={styles.page}>

          <View fixed style={{ display: "flex", flexDirection: "row", justifyContent: "center", height: 150, position: "relative" }}>
            <Image alt="" src="/images/pdfpic2.png" style={{ width: 350 }} />
            <View style={{ width: 250, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: 'center', position: "absolute", top: 0, left: 0, gap: 10 }}>
              <Image alt="" src="/images/pdflogo.png" style={{ width: 70 }} />
              <View style={{ height: 46, width: 170, display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>
                <View style={{ width: "100%", display: "flex", gap: 5, flexDirection: "row", alignContent: "center" }}>
                  <Image alt="" src="/images/pdfweb.png" style={{ width: 12 }} />
                  <Text style={{ fontSize: 8, fontFamily: "font-semi-bold" }}>www.bodyness.ir</Text>
                </View>
                <View style={{ width: "100%", display: "flex", gap: 5, flexDirection: "row", alignContent: "center" }}>
                  <Image alt="" src="/images/pdfinsta.png" style={{ width: 12 }} />
                  <Text style={{ fontSize: 8, fontFamily: "font-semi-bold" }}>Bodyness.app</Text>
                </View>
                <View style={{ width: "100%", display: "flex", gap: 5, flexDirection: "row", alignContent: "center" }}>
                  <Image alt="" src="/images/pdfwhatsapp.png" style={{ width: 12 }} />
                  <Text style={{ fontSize: 8, fontFamily: "font-semi-bold" }}>پشتیبان شما در واتساپ: 09352920712</Text>
                </View>
              </View>
            </View>
            <View style={{ width: 189, height: 85, position: "absolute", right: 0 }}>

              <View style={{ display: "flex", flexDirection: "row-reverse" }}>
                <Text style={{ fontSize: 30 }}>برنامه</Text>
                <Text style={{ fontSize: 30, fontFamily: "font-bold" }}>ورزشی</Text>
              </View>
              <View style={{ display: "flex", flexDirection: "row-reverse", gap: 4 }}>
                <Text style={{ fontSize: 8 }}>:نام و نام خانوادگی</Text>
                <Text style={{ fontSize: 8, fontFamily: "font-semi-bold" }}>{data?.userName}</Text>
              </View>
              <View style={{ display: "flex", flexDirection: "row-reverse", gap: 4, marginTop: 5 }}>
                <Text style={{ fontSize: 8 }}>:کد اشتراک</Text>
                <Text style={{ fontSize: 8, fontFamily: "font-semi-bold" }}>U-459zuj</Text>
              </View>
            </View>
          </View>
          <View style={styles.wrapper}>
            {data?.personalSportProgramDetails?.map((item, index) => (

              <View wrap={false} style={styles.box} key={index}>

                <View style={styles.header}>
                  <View style={styles.headerBox}>
                    <Text style={styles.headerTitle}>تمرین</Text>
                    <Text style={styles.headerValue}>{item?.trainingSystem}</Text>
                  </View>
                  <View style={styles.headerBox}>
                    <Text style={styles.headerTitle}>حرکت</Text>
                    <Text style={styles.headerValue}>{item?.exerciseName}</Text>
                  </View>
                  <View style={styles.headerBox}>
                    <Text style={styles.headerTitle}>عضله</Text>
                    <Text style={styles.headerValue}>{item?.muscleName}</Text>
                  </View>
                  <View style={styles.headerBox}>
                    <Text style={styles.headerTitle}>تعداد ست</Text>
                    <Text style={styles.headerValue}>{item?.setCount}</Text>
                  </View>
                </View>
                <View style={styles.table}>
                  <View style={styles.tableRowHeader}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCellHeader}>ست ها</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCellHeader}>تعداد تکرار</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCellHeader}>استراحت</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCellHeader}>tempo</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCellHeader}>توان (%)</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCellHeader}>rir (تکرار ذخیره)</Text>
                    </View>
                  </View>
                  {item?.sets?.map((row, idx) => (
                    <View style={styles.tableRow} key={idx}>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}> {idx + 1}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{row?.setRepeat}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{row?.rest} ثانیه</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{row?.tempo}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{row?.tavan}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{row?.weight}</Text>
                      </View>
                    </View>
                  ))}
                  <View style={styles.descBox}>
                    <Text style={{ fontSize: 9, color: "#7639BA" }}>:توضیحات</Text>
                    <Text style={{ fontSize: 9, width: "100%", direction: "rtl" }}>{item?.description}</Text>
                  </View>
                </View>
              </View>
            ))}
          </View>
        </Page>
      </Document>
    );
  } catch (error) {
    console.error("خطا در تولید PDF:", error.message);
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <Text>خطایی رخ داده است. لطفاً داده‌ها را بررسی کنید.</Text>
        </Page>
      </Document>
    );
  }
};

export default PDFTable;
