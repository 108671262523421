import React, { useState, useEffect } from "react";
import { svgFileUrl } from "../../../config";
import { BlobProvider } from "@react-pdf/renderer";
import { useLocation } from "react-router-dom"; // استفاده از hook برای نظارت بر مسیر

const PDFDownloader = ({ data, fileName = "document.pdf", PdfDocumentComponent }) => {
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [reloadKey, setReloadKey] = useState(0);
  const location = useLocation(); // دریافت مسیر کنونی

  // تغییر مسیر باعث ریست کردن URL جدید می‌شود
  useEffect(() => {
    setReloadKey(prev => prev + 1); // تغییر key برای رندر مجدد
  }, [location.pathname]); // نظارت بر تغییر مسیر

  // وقتی که URL تولید می‌شود، این تابع به روزرسانی URL را انجام می‌دهد
  const handleUrlUpdate = (url) => {
    setDownloadUrl(url);
  };

  return (
    <section>
      {/*   <button
        onClick={() => setReloadKey(prev => prev + 1)} // بازگذاری مجدد PDF
        className="mb-2 px-4 py-2 bg-blue-500 text-white rounded"
      >
        بازگذاری مجدد PDF
      </button> */}

      <BlobProvider key={reloadKey} document={<PdfDocumentComponent data={data} />}>
        {({ url, loading }) => {
          // تغییر URL فقط زمانی که PDF ساخته شد و loading تمام شد
          if (!loading && url !== downloadUrl) {
            handleUrlUpdate(url); // بروزرسانی URL دانلود
          }

          return loading ? (
            <section className="w-[140px] mr-auto">
              در حال ساخت pdf...
            </section>
          ) : (
            <a
              href={downloadUrl}
              download={fileName}
              className="flex justify-center items-center gap-2 w-[140px] p-2 font-semibold h-[40px] mr-auto bg-whitesmoke bg-[#fff4f4] text-red-500 rounded-full"
            >
              <span> دانلود PDF</span>
              <img className="h-full" src={svgFileUrl + "download.svg"} alt="Download" />
            </a>
          );
        }}
      </BlobProvider>
    </section>
  );
};

export default PDFDownloader;
