//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

import { useState } from "react";
import { baseFileUrl, imagesFileUrl } from "../../../../../config";
import Item from "./Item";
import { Button } from "../../../../../components/common";
import { Link } from "react-router-dom";
export default function Index({ vipPkgId, normalPkgId }) {
  // ─── Global Variable ────────────────────────────────────────────────────────────

  // ─── States ─────────────────────────────────────────────────────────────────────
  const [activeTab, setActiveTab] = useState(1);
  // ─── Functions ──────────────────────────────────────────────────────────────────

  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="xl:hidden">
      <section className={`${activeTab === 1 ? "h-auto" : "h-[78px]"} overflow-hidden mt-[64px] px-[28px] pb-[28px] transition-all rounded-[16px] border border-[#E6E8E9]`}>
        <section className="h-[78px]  flex justify-between items-center">
          <span className="text-[16px] font-bold">خرید بسته عادی 1 ماهه</span>
          <span onClick={() => setActiveTab(1)} className="flex w-[32px] justify-center items-center h-[32px] bg-[#F9F5FE] rounded-full">
            <img className="w-[20px] rotate-90" src={baseFileUrl + "/arrowRight.png"} alt="" />
          </span>
        </section>
        <section className="mt-[40px]">
          <Item type="checked" title="تمرین‌های شخصی‌سازی شده بر اساس هدف و سطح کاربر (چربی‌سوزی، افزایش حجم عضلات و ..." />
          <Item type="checked" title="ارائه تمرین‌های عمومی و پایه" />
          <Item type="unchecked" title="گزارش‌های پیشرفت منظم:  تحلیل پیشرفت کاربر در تمرینات و ارائه بازخوردهای شخصی‌سازی‌شده به طور ماهانه" />
          <Item type="unchecked" title="پشتیبانی اختصاصی : ارتباط مستقیم با مربیان  از طریق تماس تلفنی" />
          <Item type="unchecked" title="امکان سفارشی‌سازی تمرینات: کاربران می‌توانند به‌راحتی برنامه‌ها را بر اساس نیازهای خود تغییر دهند" />
          <Item type="unchecked" title="امکان ثبت‌نام در چالش‌های خاص: چالش‌های فصلی یا مناسبتی " />
          <Item type="unchecked" title="ایونت های ورزشی : امکان حضور در ایونت های ورزشی با حضور مربی " />
          <Item type="unchecked" title="سمینار های آنلاین  : شرکت در سمینار های آموزشی و رفع اشکال" />
          <Item type="unchecked" title="ثبت و مشاهده رکوردها: مشاهده دستاوردهای پیشرفت در هر بخش با استفاده از بادینس پلنر" />
        </section>
        <section className="flex w-full h-[60px] justify-center items-center mt-[10px] gap-2">
          <span className="text-[32px] font-bold">1,200,000</span>
          <span className="text-[16px] text-[#A6A9BD] font-normal">تومان</span>
        </section>
        <Link to={`/shopping-cart/${normalPkgId}`}>
          <Button className="w-full  rounded-full h-[40px] mt-[21px] transition-all duration-200">خرید بسته عادی 1 ماهه</Button>
        </Link>
      </section>
      <section className={`${activeTab === 2 ? "h-auto" : "h-[78px]"} mt-[22px] overflow-hidden px-[28px] pb-[28px] transition-all rounded-[16px] border border-[#E6E8E9]`}>
        <section className="h-[78px]  flex justify-between items-center">
          <span className="flex justify-center text-[16px] items-center font-bold  gap-2 h-[42px] rounded-full ">
            <img className="w-[21px] h-[21px]" src={imagesFileUrl + "icons/vip-badge.png"} alt="" />
            خرید بسته ویژه 3 ماهه
          </span>
          <span onClick={() => setActiveTab(2)} className="flex w-[32px] justify-center items-center h-[32px] bg-[#F9F5FE] rounded-full">
            <img className="w-[20px] rotate-90" src={baseFileUrl + "/arrowRight.png"} alt="" />
          </span>
        </section>
        <section className="mt-[40px]">
          <Item type="checked" title="تمرین‌های شخصی‌سازی شده بر اساس هدف و سطح کاربر (چربی‌سوزی، افزایش حجم عضلات و ..." />
          <Item type="checked" title="ارائه تمرین‌های عمومی و پایه" />
          <Item type="checked" title="گزارش‌های پیشرفت منظم:  تحلیل پیشرفت کاربر در تمرینات و ارائه بازخوردهای شخصی‌سازی‌شده به طور ماهانه" />
          <Item type="checked" title="پشتیبانی اختصاصی : ارتباط مستقیم با مربیان  از طریق تماس تلفنی" />
          <Item type="checked" title="امکان سفارشی‌سازی تمرینات: کاربران می‌توانند به‌راحتی برنامه‌ها را بر اساس نیازهای خود تغییر دهند" />
          <Item type="checked" title="امکان ثبت‌نام در چالش‌های خاص: چالش‌های فصلی یا مناسبتی " />
          <Item type="checked" title="ایونت های ورزشی : امکان حضور در ایونت های ورزشی با حضور مربی " />
          <Item type="checked" title="سمینار های آنلاین  : شرکت در سمینار های آموزشی و رفع اشکال" />
          <Item type="checked" title="ثبت و مشاهده رکوردها: مشاهده دستاوردهای پیشرفت در هر بخش با استفاده از بادینس پلنر" />
        </section>
        <section className="flex w-full h-[60px] justify-center items-center mt-[10px] gap-2">
          <span className="text-[32px] font-bold">4,000,000</span>
          <span className="text-[16px] text-[#A6A9BD] font-normal">تومان</span>
        </section>
        <Link to={`/shopping-cart/${vipPkgId}`}>
          <Button className="w-full  rounded-full h-[40px] mt-[21px] transition-all duration-200">خرید بسته ویژه 3 ماهه</Button>
        </Link>
      </section>
    </section>
  );
}
