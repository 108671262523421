import { useState, useEffect } from "react";
import { baseFileUrl, svgFileUrl } from "../../../../config";
import Item from "./components/Item";
//
// ────────────────────────────────────────────────────────── I ──────────
//   :::::: C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
//

export default function Index() {
  // ─── Global Variable ────────────────────────────────────────────────────────────
  const teamList = [
    {
      name: "عرفان نوخیز",
      role: "هم بنیانگذار",
      picture: "/images/erfan-nokhiz-1.jpg",
      description: "",
    },
    {
      name: "علی مشیری",
      role: "برنامه نویس ارشد backend",
      picture: "/images/alimoshiri.jpg",
      description: (
        <>
          <p>من علی مشیری امین هستم، توسعه‌دهنده ارشد نرم‌افزار با بیش از 12 سال تجربه در طراحی و اجرای سامانه‌های پیشرفته ملی و سازمانی. طی این سال‌ها، در پروژه‌های بزرگ و متنوعی مشارکت داشته‌ام و در حال حاضر، تمرکزم بر روی توسعه سامانه بادی نس (Body-ness) است که با ارائه راهکارهای نوآورانه، به کاربران در دستیابی به تناسب اندام و سلامت پایدار کمک می‌کند.</p>
          <span className="font-bold">دستاوردهای کلیدی</span>
          <p>من کارت مشهد(شرکت اتیک پارس): طراحی و توسعه سامانه‌ای جامع برای حمل‌ونقل عمومی که امکان مدیریت خدمات شهری را برای شهروندان فراهم کرد. این پروژه تجربه‌ای ارزشمند در بهبود زیرساخت‌های خدمات عمومی بود.</p>
          <p>کارت ملی هوشمند(شرکت ایزایران): مشارکت در پروژه‌ای ملی برای دیجیتالی‌سازی خدمات دولتی و افزایش امنیت اطلاعات. این پروژه یکی از نقاط عطف در مسیر تحول دیجیتال ایران محسوب می‌شود.</p>
          <p>توسعه سامانه‌های صنعتی (شرکت فناپ): شامل ایجاد اتوماسیون برای محیط صنعتی کارخانه فولادسازی میدکو. </p>
          <p>Core Banking بانک مسکن (شرکت ناواکو): طراحی زیرساخت‌های یکپارچه بانکی برای مدیریت فرآیندهای مالی در شرکت ناواکو که تحولی بزرگ در ارائه خدمات بانکی ایجاد کرد. </p>
          <p>بهبود سامانه‌های بورسی(شرکت رایان بورس): از جمله مدیریت کارگزاری و صندوق‌های بورسی . </p>
          <span className="font-bold">فعالیت‌های من در شرکت سداد:</span>
          <p>سامانه اوراق الکترونیک: طراحی و توسعه سیستمی برای مدیریت اوراق بهادار با هدف افزایش شفافیت و کارایی معاملات مالی</p>
          <p>سامانه تسهیلات و وام: بهینه‌سازی سیستم‌های تسهیلات و وام، طراحی ویژگی‌های جدید و رفع چالش‌ها برای بهبود عملکرد این سامانه‌ها. چشم‌انداز حرفه‌ای</p>
          <p>هدف من همیشه ارائه راه‌حل‌هایی است که نه‌تنها نیازهای کاربران را پاسخ دهد، بلکه کیفیت زندگی و بهره‌وری آن‌ها را بهبود بخشد. پروژه بادی نس نیز گامی در همین مسیر است، برای ایجاد تأثیری مثبت و ماندگار بر سلامت و سبک زندگی افراد.</p>
        </>
      ),
    },
    {
      name: " عاطفه قدوسی",
      role: " فرانت اند",
      picture: "/images/atigh-bg.jpg",
      description:
        "من یک توسعه‌دهنده فرانت‌اند با یک سال تجربه کار حرفه‌ای هستم که علاقه زیادی به خلق رابط‌های کاربری زیبا و کاربرپسند دارم. در این مدت با ابزارها و فناوری‌هایی مانند Next.js، React، و Tailwind CSS کار کرده‌ام و تجربه ساخت وب‌سایت‌ها و برنامه‌های کاربردی سریع و مدرن را دارم. تمرکز من روی پیاده‌سازی کدهای تمیز، بهینه و هم‌راستا با نیازهای کاربران است و همواره به دنبال یادگیری و بهبود مهارت‌هایم در حوزه توسعه وب هستم. همکاری در تیم‌های پویا و پروژه‌های چالش‌برانگیز به من انگیزه می‌دهد تا بهترین خودم را ارائه کنم.تمرکز من روی پیاده‌سازی کدهای تمیز، بهینه و هم‌راستا با نیازهای کاربران است و همواره به دنبال یادگیری و بهبود مهارت‌هایم در حوزه توسعه وب هستم. همکاری در تیم‌های پویا و پروژه‌های چالش‌برانگیز به من انگیزه می‌دهد تا بهترین خودم را ارائه کنم.                  تمرکز من روی پیاده‌سازی کدهای تمیز، بهینه و هم‌راستا با نیازهای کاربران است و همواره به دنبال یادگیری و بهبود مهارت‌هایم در حوزه توسعه وب هستم. همکاری در تیم‌های پویا و پروژه‌های چالش‌ برانگیز به من انگیزه می‌دهد تا بهترین خودم را ارائه کنم.",
    },
    {
      name: " راضیه اخوان",
      role: "طراح UI,UX",
      picture: "/images/raziye-1.jpg",
      description: (
        <>
          <p>
            من یک طراح محصول هستم که از سال ۲۰۲۰ مسیر حرفه‌ای خود را با تمرکز بر طراحی تجربه‌های کاربری هدفمند و رابط‌های بصری جذاب آغاز کرده‌ام. در این مسیر، ترکیب خلاقیت و تفکر استراتژیک به من امکان داده تا محصولاتی طراحی کنم که فراتر از زیبایی بصری، ارزش واقعی برای کاربران و کسب‌وکارها خلق کنند. پیش از ورود به دنیای طراحی، مدرک کارشناسی خود را در رشته مهندسی نرم‌افزار و مدرک کارشناسی ارشد را در رشته هوش مصنوعی دریافت کردم و حدود ۸ سال فعالیت آکادمیک، به عنوان مدرس دروس کامپیوتر در مقطع کارشناسی در دانشگاه‌های غیرانتفاعی و علمی کاربردی تدریس کردم. این
            تجربه به من مهارت‌های تحلیلی و تفکر سیستمی بخشید که پایه‌های محکمی برای ورودم به دنیای طراحی محصول شد. در سال ۲۰۱۹، علاقه‌ام به حل مسائل پیچیده و خلق راه‌حل‌های نوآورانه، مرا به سمت یادگیری طراحی تجربه کاربری (UX) و رابط کاربری (UI) سوق داد و از سال ۲۰۲۰ فعالیت حرفه‌ای خود را در این حوزه آغاز کردم. تخصص من در تحلیل نیازها و رفتار کاربران، شناسایی چالش‌ها و تبدیل آن‌ها به راه‌حل‌های طراحی کاربردی است. اعتقاد دارم که هر جزئیات کوچک می‌تواند تأثیری بزرگ بر تجربه کلی بگذارد. یکی از پروژه‌های ارزشمندی که افتخار همکاری در آن را داشتم، پروژه
            "بادینس" بود. این پروژه که به ارائه رژیم‌های غذایی و برنامه‌های ورزشی برای بهبود سلامت و تناسب اندام کاربران اختصاص دارد، فرصتی فوق‌العاده برای یادگیری و کسب تجربیات جدید برای من فراهم کرد. در این پروژه، توانستم نیازهای کاربران را به‌طور عمیق‌تر درک کرده و راه‌حل‌های طراحی کاربردی و موثر ارائه دهم. همچنین، این تجربه به من کمک کرد تا به اهمیت تطبیق طراحی با نیازهای خاص کاربران در حوزه سلامت و تناسب اندام پی ببرم. طراحی برای من، چیزی فراتر از خلق یک محصول است؛ این یک فرآیند پویا برای ارتباط با انسان‌ها، حل مشکلات آن‌ها و ایجاد تجربیاتی است که
            هم‌زمان ساده و تأثیرگذار باشند. هدف من همیشه ساخت محصولاتی هست که علاوه‌ بر تحقق اهداف کسب‌و‌کارها، زندگی کاربران را ساده‌تر کرده و تجربه‌ای معنادار برای آن‌ها ایجاد کند.
          </p>
          <p>یش از ورود به دنیای طراحی، مدرک کارشناسی خود را در رشته مهندسی نرم‌افزار و مدرک کارشناسی ارشد را در رشته هوش مصنوعی دریافت کردم و حدود ۸ سال فعالیت آکادمیک، به عنوان مدرس دروس کامپیوتر در مقطع کارشناسی در دانشگاه‌های غیرانتفاعی و علمی کاربردی تدریس کردم. این تجربه به من مهارت‌های تحلیلی و تفکر سیستمی بخشید که پایه‌های محکمی برای ورودم به دنیای طراحی محصول شد.</p>
          <p>در سال ۲۰۱۹، علاقه‌ام به حل مسائل پیچیده و خلق راه‌حل‌های نوآورانه، مرا به سمت یادگیری طراحی تجربه کاربری (UX) و رابط کاربری (UI) سوق داد و از سال ۲۰۲۰ فعالیت حرفه‌ای خود را در این حوزه آغاز کردم.</p>
          <p>تخصص من در تحلیل نیازها و رفتار کاربران، شناسایی چالش‌ها و تبدیل آن‌ها به راه‌حل‌های طراحی کاربردی است. اعتقاد دارم که هر جزئیات کوچک می‌تواند تأثیری بزرگ بر تجربه کلی بگذارد.</p>
          <p>یکی از پروژه‌های ارزشمندی که افتخار همکاری در آن را داشتم، پروژه "بادینس" بود. این پروژه که به ارائه رژیم‌های غذایی و برنامه‌های ورزشی برای بهبود سلامت و تناسب اندام کاربران اختصاص دارد، فرصتی فوق‌العاده برای یادگیری و کسب تجربیات جدید برای من فراهم کرد. در این پروژه، توانستم نیازهای کاربران را به‌طور عمیق‌تر درک کرده و راه‌حل‌های طراحی کاربردی و موثر ارائه دهم. همچنین، این تجربه به من کمک کرد تا به اهمیت تطبیق طراحی با نیازهای خاص کاربران در حوزه سلامت و تناسب اندام پی ببرم.</p>
          <p>طراحی برای من، چیزی فراتر از خلق یک محصول است؛ این یک فرآیند پویا برای ارتباط با انسان‌ها، حل مشکلات آن‌ها و ایجاد تجربیاتی است که هم‌زمان ساده و تأثیرگذار باشند. هدف من همیشه ساخت محصولاتی هست که علاوه‌ بر تحقق اهداف کسب‌و‌کارها، زندگی کاربران را ساده‌تر کرده و تجربه‌ای معنادار برای آن‌ها ایجاد کند.</p>
        </>
      ),
    },
    {
      name: "خانم ملیکه مهاجرانی",
      role: "متخصص تغذیه",
      picture: "/images/doctor1.jpg",
      description: (
        <>
          <p>با 7 سال تجربه در زمینه رژیم درمانی و تغذیه سالم، من به عنوان یک متخصص تغذیه، تجربیاتم را از طریق جامع‌ترین اپلیکیشن تغذیه ورزشی کشور، یعنی بادینس، با شما عزیزان به اشتراک می‌گذارم. در طی سال‌ها کار با مراجعین مختلف، به این نتیجه رسیدم که هر فرد نیازهای خاص خود را دارد، بنابراین طراحی رژیم‌های متناسب با شرایط هر شخص برای من اولویت دارد.</p>
          <p>متخصص در رژیم‌های کتوژنیک، گیاه‌خواری و رژیم‌های متعادل، من همواره دانش خود را با آخرین تحقیقات علمی به روز کرده و به مراجعین خود در دستیابی به اهدافی مانند کاهش وزن، بهبود بیماری‌های مزمن و افزایش عملکرد ورزشی کمک کرده‌ام.</p>
          <p>هدف من نه تنها ارتقای سلامت جسمی، بلکه بهبود روحیه و کیفیت زندگی افراد است. همیشه آماده‌ام تا به شما در مسیر تغییرات مثبت و دستیابی به سبک زندگی سالم کمک کنم</p>
        </>
      ),
    },
    {
      name: "دکتر  سارا صادقی",
      role: "متخصص تغذیه",
      picture: "/images/doctor3.jpg",
      description: (
        <>
          <p>من سارا صادقی هستم، متخصص تغذیه. در سال ۱۴۰۰، مدرک لیسانس علوم تغذیه را از دانشگاه علوم تغذیه تبریز دریافت کردم و همان سال با کسب رتبه ۳ کشوری وارد مقطع فوق‌لیسانس رشته تغذیه بالینی در دانشگاه علوم متخصص تغذیه تهران شدم. امسال نیز افتخار کسب رتبه یک کتبی آزمون دکتری تخصصی را داشتم.</p>
          <p>تحصیلات و تجربیاتم در زمینه تغذیه بالینی، به من این امکان را داده است که مسائل تغذیه‌ای را از دیدگاه علمی و جامع بررسی کنم. در طول دوران تحصیل، تجربیات ارزشمندی در بیمارستان شریعتی دانشگاه تهران کسب کردم. در این بیمارستان، در بخش‌های مختلف و کلینیک سرپایی، در فرآیند درمان تغذیه‌ای بیماران نقش داشتم. این تجربیات به من کمک کرد تا با چالش‌های واقعی در زمینه تغذیه و سلامت روبه‌رو شوم و راه‌حل‌های کاربردی و مؤثرتری برای آن‌ها ارائه دهم.</p>
          <p>علاوه بر این، علاقه من به فعالیت‌های تخصصی باعث شد که رژیم‌درمانی آنلاین را برای مراجعین با اهداف متنوع مانند کاهش و افزایش وزن، مدیریت بیماری‌های تغذیه‌ای و رژیم‌های مخصوص ورزشکاران آغاز کنم. این تجربه به من نشان داد که چگونه می‌توان از تکنولوژی برای ارائه خدمات تغذیه‌ای گسترده و در دسترس استفاده کرد.</p>
          <p>من عمیقاً به تأثیر تغذیه سالم بر بهبود کیفیت زندگی باور دارم و همواره تلاش کرده‌ام با ارائه راهکارهای علمی و عملی، به مراجعینم کمک کنم تا به اهداف سلامتی خود برسند. اکنون بسیار خوشحالم که از طریق اپلیکیشن بادینس، که بستری جامع و در دسترس برای افراد با اهداف مختلف است، می‌توانم همراه شما در مسیر سلامتی و دستیابی به اهداف تغذیه‌ای‌تان باشم. امیدوارم بتوانم گامی مثبت در بهبود کیفیت زندگی شما بردارم</p>
        </>
      ),
    },
    {
      name: " آقای ابوالفضل لاجوردی",
      role: "متخصص تغذیه",
      picture: "/images/doctor2.jpg",
      description: (
        <>
          <p>با تحصیلات کارشناسی ارشد در رشته علوم تغذیه از دانشکده پزشکی، هدف من کمک به افراد برای دستیابی به بهترین ورژن خود از طریق ترویج سبک زندگی سالم است. علاقه‌ام به ورزش و سبک زندگی سالم مرا به سمت مطالعه و کسب دانش جدید در این زمینه سوق داده است.</p>
          <p>من معتقدم که انتخاب غذایی صحیح و اصلاح سبک زندگی می‌تواند تأثیر عمیقی بر سلامت جسم و روح داشته باشد. با من همراه شوید تا به زندگی‌ای سالم‌تر، شاداب‌تر و پرانرژی دست یابید. من در کنار شما هستم تا با تغذیه مناسب و رژیم درمانی به زندگی‌ای پر از سلامتی و نشاط برسید.</p>
        </>
      ),
    },
    {
      name: " آقای محسن زیوری",
      role: "مربی",
      picture: "/images/zivariinter1.jpg",
      description: (
        <section>
          <p>من محسن زیوری هستم، فارغ‌التحصیل رشته تکنولوژی شبکه‌های کامپیوتری و فردی که همواره در مسیر تحقق رویاها و اهدافش قدم برداشته است. از نوجوانی، علاقه‌ام به ورزش باعث شد در رشته کیوکوشین کاراته فعالیت کنم و به موفقیت‌های ارزشمندی دست یابم، از جمله کسب عنوان تکنیکی‌ترین فایتر مسابقات قهرمان قهرمانان تیم ملی امید.</p>
          <p>با وجود این موفقیت‌ها، به دلیل برخی چالش‌ها و ناملایمات، چند سالی از دنیای ورزش فاصله گرفتم و به دنیای کسب‌وکار بین‌المللی وارد شدم. در این دوران، تجربه‌های گرانبهایی کسب کردم و علاقه‌ام به کارآفرینی بیشتر شد. این علاقه باعث شد اولین استارتاپ خود را در حوزه گردشگری سلامت راه‌اندازی کنم. هرچند این پروژه در زمان رشد با بحران جهانی کرونا مواجه شد و به سرانجام نرسید، اما تجربه آن برایم بسیار ارزشمند بود و مسیر آینده‌ام را روشن‌تر کرد.</p>
          <p>علاقه شخصی‌ام به ورزش و سلامت و پیشنهادات دوستان و همکارانم، من را به بازگشت به دنیای ورزش ترغیب کرد. در این مسیر، دوره‌های مختلف مربیگری و تغذیه ورزشی را در داخل و خارج از کشور گذراندم و موفق به دریافت مدارک معتبری از جمله:</p>
          <ul>
            <li>IFBB</li>
            <li>NSCA</li>
            <li>ISSA</li>
            <li>Advanced Fitness Nutrition Coach</li>
            <li>International Advanced Coach Update 2023</li>
          </ul>
          <p>نتیجه این تلاش‌ها، شکل‌گیری ایده اپلیکیشن بادینس بود؛ پروژه‌ای که پس از یک سال و نیم کار مداوم، به ثمر نشسته است. بادینس به‌عنوان یک مرجع در زمینه سلامت، تغذیه و ورزش طراحی شده است و اهداف متعددی را دنبال می‌کند، از جمله:</p>
          <ul>
            <li>آموزش صحیح حرکات ورزشی مانند فیتنس و بدن‌سازی.</li>
            <li>ارائه دوره‌های تخصصی مانند آموزش حرکات بدنسازی، چربی‌سوزی، و دوره‌های مخصوص سالمندان.</li>
            <li>ارزیابی اولیه مخاطبان برای انتخاب بهترین برنامه ورزشی و تغذیه‌ای.</li>
          </ul>
          <p>هدف اصلی من از راه‌اندازی این اپلیکیشن، ایجاد بستری جامع برای ارتقای سلامتی و کیفیت زندگی مردم عزیز کشورم و در آینده‌ای نزدیک، ارائه این خدمات به مخاطبان بین‌المللی است. امیدوارم بتوانم تخصص و تجربه خود را در خدمت به مردم قرار دهم و سلامتی همراه با دانش و مهارت برای همه به ارمغان بیاورم.</p>
        </section>
      ),
    },
    {
      name: "خانم نگار تارویردی",
      role: "مربی",
      picture: "/images/morabi3.png",
      description: (
        <>
          <p>متولد ۲۱ تیر ۱۳۶۹ و فارغ‌التحصیل رشته فیزیک، با افتخار ۱۲ سال تجربه در حوزه ورزشی را با خود همراه دارم. به‌عنوان مربی و راهنمای کسانی که سلامتی و زیبایی بدن را جزو اولویت‌های زندگی خود می‌دانند، تلاش می‌کنم تا مسیری هموار برای دستیابی به این اهداف فراهم کنم. این مسیر، نیازمند علم و تجربه کافی است و من با تمام توان، مخاطبانم را با انگیزه و انرژی بیشتر تا رسیدن به مقصد همراهی می‌کنم.</p>
          <p>تمرین اصولی و بدون نگرانی از آسیب‌های احتمالی، یکی از اولویت‌های اصلی من است. آموزش تکنیک‌های صحیح و اجرای درست حرکات ورزشی، مهم‌ترین بخش از کار من محسوب می‌شود.</p>
          <p>
            سوابق من شامل:
            <br />
            <p>دریافت مدارک تخصصی از فدراسیون‌های مرتبط (پرورش اندام و همگانی) ریاست کمیته کار با دستگاه در هیأت آمادگی جسمانی شمیرانات فعالیت در باشگاه‌ها و مجموعه‌های ورزشی معتبر و عالی‌رتبه تهران ورزش برای من همیشه با عشق و تعهد همراه بوده است. سبک زندگی‌ام به‌عنوان یک مربی و ورزشکار، نه تنها حرفه‌ای بلکه منبع الهام من برای تشویق دیگران است. با من می‌توانید تجربه‌ای لذت‌بخش، علمی و ایمن از تمرینات ورزشی داشته باشید. امیدوارم بتوانم همراه مناسبی برای شما در این مسیر باشم.</p>
          </p>
        </>
      ),
    },
    {
      name: "خانم بیتا حسینی ",
      role: "مربی",
      picture: "/images/morabi2.jpg",
      description: (
        <>
          <p>من بیتا حسینی هستم، مربی فیتنس و بدنسازی با بیش از 12 سال سابقه مربیگری و دارای مدرک بین‌المللی در فیتنس و حرکات اصلاحی. هدف من در این مسیر، ایجاد انگیزه، ترویج فرهنگ سالم زیستی و کمک به داشتن لایف استایلی سالم و زیبا برای همه افراد است.</p>
          <p>اولویت اصلی من در حرفه‌ام، تاکید بر قدرت برتر بدنی و ذهنی و ارتقای آمادگی جسمانی در تمام سنین است. باور دارم که این موارد، همراه با حس خوب و انرژی مثبت، می‌تواند تغییرات بزرگی در کیفیت زندگی افراد ایجاد کند.</p>
          <p>رای دستیابی به این هدف، همواره در حال یادگیری و به‌روزرسانی دانش و مهارت‌های خود هستم. روش‌های من در طراحی تمرینات و برنامه‌های تغذیه، بر اساس شرایط فیزیکی، اقلیمی و حتی ژنتیکی هر فرد تنظیم می‌شوند تا تجربه‌ای کاملاً شخصی‌سازی‌شده و موثر را ارائه کنم.</p>
          <p>پکیج‌های تمرینی من، چه به صورت حضوری و چه آنلاین، به گونه‌ای طراحی می‌شوند که همیشه جذاب و متنوع باشند. این تنوع نه تنها از تمرین‌زدگی جلوگیری می‌کند، بلکه باعث پیشرفت مستمر و ایجاد تغییرات قابل مشاهده در شاگردانم می‌شود.</p>
          <p>برای افرادی که به دنبال تجربه‌ای خاص‌تر هستند، پکیج‌های VIP طراحی کرده‌ام که شامل امتیازات و برنامه‌های جذاب‌تر است و تاثیرگذاری تمرینات را چند برابر می‌کند.</p>
          <p>افرادی که به جمع ارتش فیتنس من می‌پیوندند، سفری هیجان‌انگیز را آغاز می‌کنند که در آن، گام‌به‌گام همراهشان خواهم بود تا به اهدافشان برسند. امیدوارم شما هم به این گروه بپیوندید و همراه من، مسیر داشتن زندگی سالم و بدنی قدرتمند را تجربه کنید.</p>
        </>
      ),
    },
    {
      name: "علی درگاهی",
      role: "برنامه نویس ارشد frontend",
      picture: "/images/001.jpg",
      description: (
        <>
          <p>من یک توسعه‌دهنده فرانت‌اند با ۸ سال سابقه کار حرفه‌ای هستم. تجربه من در طراحی و توسعه رابط‌های کاربری باعث شده تا پروژه‌های مختلفی را در حوزه‌های گوناگون با موفقیت به سرانجام برسانم. تخصص اصلی من در استفاده از فناوری‌های مدرن نظیر React، Next.js و TypeScript است و در کنار آن‌ها از معماری کلین (Clean Architecture) برای ایجاد ساختاری منظم و قابل نگهداری بهره می‌برم.</p>
          <p>در طول دوران کاری‌ام، بر روی پروژه‌های بزرگ و تاثیرگذاری مانند نرم‌افزارهای سازمانی پخش دارو، پروژه تاکسی اینترنتی هیثرو و همچنین پروژه‌های مرتبط با آموزش و پرورش کار کرده‌ام. این تجربیات به من کمک کرده‌اند تا مهارت‌های خود را در طراحی سیستم‌های پیچیده و ارائه تجربه‌های کاربری بهینه تقویت کنم.</p>
          <p>تمرکز من همیشه بر ارائه راه‌حل‌های سریع، کارآمد و زیبا برای نیازهای مشتریان است. همچنین با ابزارهای مدیریت وظایف مانند Trello و سیستم‌های کنترل نسخه نظیر Git به خوبی آشنا هستم.</p>
          <p>علاقه‌مند به چالش‌های جدید هستم و از مشارکت در پروژه‌هایی که خلاقیت، دانش و مهارت‌های من را به چالش می‌کشند، لذت می‌برم.</p>
        </>
      ),
    },
  ];
  // ─── States ─────────────────────────────────────────────────────────────────────
  const [open, setOpen] = useState(false);
  /*   const [closeLeft, setCloseLeft] = useState(0);
  const [closeTop, setCloseTop] = useState(0); */
  const [teamInfo, setTeamInfo] = useState();
  // ─── Life Cycle ─────────────────────────────────────────────────────────────────

  // ─── Functions ──────────────────────────────────────────────────────────────────
  useEffect(() => {
    const div = document.getElementById("myDiv");
    if (!div) {
      console.warn("Element with id 'myDiv' not found");
      return;
    }
    /*     const handleMouseMove = (event) => {
      const rect = div.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      setCloseLeft(x - 40);
      setCloseTop(y - 40);
    };
    div.addEventListener("mousemove", handleMouseMove);
    return () => {
      div.removeEventListener("mousemove", handleMouseMove);
    }; */
  }, []);
  // ─── Functions ──────────────────────────────────────────────────────────────────
  const showInfo = (index) => {
    setOpen(true);
    setTeamInfo(teamList[index]);
  };
  //
  // ──────────────────────────────────────────────────── I ──────────
  //   :::::: R E N D E R : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────
  //
  return (
    <section className="xl:w-[1128px] w-[90%]  mx-auto mt-[20px] mb-[50px] xl:mb-[150px] h-auto ">
      <section className="grid xl:grid-cols-4 grid-cols-2  relative w-full  xl:w-[1128px]  mx-auto gap-6">
        <section className="hidden xl:block"></section>
        <Item social={false} index={7} showInfo={showInfo} picture={baseFileUrl + "/images/mohsenzivaribg.png"} title=" آقای محسن زیوری" text="بنیان گذار" />
        <Item social={false} index={0} showInfo={showInfo} picture={baseFileUrl + "/images/erfannokhiz.png"} title="آقای عرفان نوخیز" text="هم بنیان گذار" />
        <section className="xl:col-span-4 mt-4 xl:mt-8 col-span-2 flex items-center xl:justify-between gap-3 xl:mb-8  w-full">
          <img src={baseFileUrl + "/images/VectorTeam-r.png"} alt="" className="xl:w-[400px] w-[30%] " />
          <h2 className="text-purple  text-sm xl:text-[20px] font-bold"> تیم برنامه نویسان</h2>
          <img src={baseFileUrl + "/images/VectorTeam-l.png"} alt="" className="xl:w-[400px] w-[30%]" />
        </section>
        <Item social={false} index={1} showInfo={showInfo} picture={baseFileUrl + "/images/moshiribg.png"} title="آقای علی مشیری امین" text="برنامه نویس ارشد backend" />
        <Item social={false} index={10} showInfo={showInfo} picture={baseFileUrl + "/images/amooooo aliiiiiii.png"} title="  آقای علی درگاهی" text="برنامه نویس  ارشد frontend" />
        <Item social={false} index={2} showInfo={showInfo} picture={baseFileUrl + "/images/ghoddusi.png"} title=" خانم عاطفه قدوسی" text="   برنامه نویس frontend" />
        <Item social={false} index={3} showInfo={showInfo} picture={baseFileUrl + "/images/raziye1.jpg"} title="خانم راضیه اخوان" text="طراح UI,UX" />
        <section className="xl:col-span-4  mt-4 xl:mt-8 col-span-2 flex items-center xl:justify-between gap-3 xl:mb-8  w-full">
          <img src={baseFileUrl + "/images/VectorTeam-r.png"} alt="" className="xl:w-[400px] w-[30%] " />
          <h2 className="text-purple  text-sm xl:text-[20px] font-bold">تیم متخصص تغذیه</h2>
          <img src={baseFileUrl + "/images/VectorTeam-l.png"} alt="" className="xl:w-[400px] w-[30%]" />
        </section>
        <Item index={4} showInfo={showInfo} picture={baseFileUrl + "/images/doctor1.png"} title="خانم ملیکه مهاجرانی" text=" متخصص تغذیه" />
        <Item index={5} showInfo={showInfo} picture={baseFileUrl + "/images/doctor5.jpg"} title="  خانم سارا صادقی" text="  کارشناس ارشد  تغذیه بالینی" />
        <Item index={6} showInfo={showInfo} picture={baseFileUrl + "/images/doctor4.jpg"} title="آقای ابوالفضل لاجوردی" text=" مشاور تغذیه" />
        <section className="xl:col-span-4 mt-4 xl:mt-8 col-span-2 flex items-center xl:justify-between gap-3 xl:mb-8  w-full">
          <img src={baseFileUrl + "/images/VectorTeam-r.png"} alt="" className="xl:w-[400px] w-[30%] " />
          <h2 className="text-purple  text-sm xl:text-[20px] font-bold"> تیم مربیان</h2>
          <img src={baseFileUrl + "/images/VectorTeam-l.png"} alt="" className="xl:w-[400px] w-[30%]" />
        </section>
        <Item index={8} showInfo={showInfo} picture={baseFileUrl + "/images/morabi3.png"} title="خانم نگار تارویردی" text="مربی" />
        <Item index={9} showInfo={showInfo} picture={baseFileUrl + "/images/morabi2.png"} title="  خانم بیتا حسینی" text="مربی" />
        {/* <Item index={10} showInfo={showInfo} picture={baseFileUrl + "/images/morabi1.png"} title="خانم فاطمه گنجی" text="مربی" /> */}
      </section>
      <section id="myDiv" className={`fixed flex w-full h-full bg-slate-200 transition-all duration-500 top-[9%] xl:top-0 ${open ? "right-0" : "-right-[100%] xl:right-[-110%]"} z-[20001]`}>
        {/*  <section onClick={() => setOpen(false)} className={`w-[80px] absolute xl:flex justify-center hidden items-center h-[80px] rounded-full border cursor-pointer border-black`} style={{ left: closeLeft, top: closeTop }}>
          <img className="w-10 h-10" alt="" width={40} height={40} src={svgFileUrl + "/close.svg"} />
        </section>
        <section onClick={() => setOpen(false)} className={`w-[40px] absolute left-4 top-4 flex justify-center xl:hidden items-center h-[40px] rounded-full border cursor-pointer border-black`}>
          <img alt="" width={20} height={20} src={svgFileUrl + "/close.svg"} />
        </section> */}
        <button className="absolute rounded-full border border-silver p-3 top-5 left-5" onClick={() => setOpen(false)}>
          <img alt="" width={20} height={20} src={svgFileUrl + "/close.svg"} />
        </button>
        <section className="w-[35%] hidden h-full xl:flex justify-center items-center bg-slate-300">
          <img width={500} height={400} className="rounded-lg h-[80%] w-auto" src={teamInfo?.picture} alt="" />
        </section>
        <section className="w-[100%] xl:w-[65%] p-3 xl:py-[40px] xl:px-[80px] flex h-full overflow-auto flex-col justify-center xl:bg-slate-100">
          <section className="w-[700px] max-w-full xl:mr-[100px]">
            <h2 className="text-2xl xl:text-[30px] text-right font-bold">{teamInfo?.name}</h2>
            <h3 className="xl:text-[22px]">{teamInfo?.role}</h3>
            <p className="text-justify text-sm xl:text-base leading-7 h-[400px] overflow-auto px-5 font-light xl:leading-8 mt-8">{teamInfo?.description}</p>
          </section>
        </section>
      </section>
    </section>
  );
}
